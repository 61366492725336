window.jQuery = window.$ = require('jquery');
window.Swiper = require('swiper8/swiper-bundle');

let processInclude = require('base/util');
const utils = require('./utils');
const videoUtils = require('./videoUtils');

window.isMargiela = true;

$(document).ready(function() {
  const currentPage = document.querySelector('body').getAttribute('id');
  processInclude(require('core/login'));
  processInclude(require('./forms'));
  processInclude(require('./banner-notification'));
  processInclude(require('./miniCart'));

  processInclude(require('./search/search-suggestions'));
  processInclude(require('./selectElement'));
  processInclude(require('./menuHelper'));
  processInclude(require('./countrySelectorHelper'));
  processInclude(require('core/components/wishlist'));

  videoUtils.videoInit();
  // utils.mainContentSpacer();
  // utils.videoButtonsHide();
  utils.initialHeight();
  utils.mainContentSpacer();
  if(currentPage === 'wishlist-show' || currentPage === 'home-show') {
    processInclude(require('tealium/tealiumEvents'));
  }

  const chatbotEntryPoint = document.getElementById('chatbot-entry-point-bubble');
  const chatbotEntryPointBalloon = document.getElementById('chatbot-entry-point-balloon');

  if (chatbotEntryPoint && chatbotEntryPointBalloon) {
    setTimeout(() => {
      chatbotEntryPointBalloon.classList.add('active');
      chatbotEntryPointBalloon.querySelector('.close-balloon').addEventListener('click', (e) => {
        e.preventDefault();
        chatbotEntryPointBalloon.classList.remove('active');
      })
    }, 3000)
  }
});
require('base/thirdParty/bootstrap');
require('core/components/spinner');

